.CourseTables .el-radio .el-radio__label {
  display: none;
}
.CourseTables .el-radio .el-radio__inner {
  width: 18px;
  height: 18px;
  border-color: #777;
}
.CourseTables .el-radio .el-radio__inner:hover {
  border-color: #5c6be8;
}
.CourseTables .el-radio .el-radio__input.is-checked .el-radio__inner {
  border-color: #5c6be8;
  background-color: #5c6be8;
}
.CourseTables .Batch {
  padding: 1rem 1rem;
}
.CourseTables .Batch .BatchBox {
  display: flex;
  height: 120px;
  border: 1px solid #EBEEF5;
  border-radius: 5px;
}
.CourseTables .Batch .BatchBox .BatchBoxFirst {
  width: 260px;
  background: #f2f7fd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.CourseTables .Batch .BatchBox .BatchBoxSecond {
  width: 100%;
}
.CourseTables .Batch .BatchBox .BatchBoxSecond .BatchforBox {
  display: flex;
  flex-wrap: wrap;
}
.CourseTables .Batch .BatchBox .BatchBoxSecond .BatchforBox .BatchforFlex {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  cursor: pointer;
}
.CourseTables .Batch .BatchBox .BatchBoxSecond .BatchforBox .BatchforFlex .BatchImgBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.CourseTables .Batch .BatchBox .BatchBoxSecond .BatchforBox .BatchforFlex img {
  width: 16px;
  height: 16px;
}
