


















































































































































































.teacher-picker {
  .check-all-btn {
    font-size: 22px;
    line-height: inherit;
    color: #409eff;
    cursor: pointer;
  }
  .check-all-btn:hover {
    color: #66b1ff;
  }
}
