






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.createdCourse {
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;
    font-size: 14px;
  }

  .level {
    padding: 3px;
    border-radius: 6px;
    color: #333;
    background-color: #e0f2ff;
    min-width: 20px;
    text-align: center;
  }
}

.formCom {
  .el-form-item {
    margin-bottom: 5px;
  }

  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }

  .el-select {
    width: 100%;
  }

  .el-progress {
    width: 200px;
  }

  .chapterl-trees {
    padding: 15px 10px 15px 0;

    .el-tree-node__content {
      height: 36px;
      margin-bottom: 15px;
    }

    .draggable-item {
      margin-left: 10px;

      em {
        margin-right: 5px;
      }
    }

    .trees-btns {
      .showbtn {
        color: #fff;
      }

      margin-left: 15px;

      &>em,
      .handle{
        border:none;
        >em {
          color: #4089fa;
          font-size: 14px;
          cursor: pointer;

          &:not(:last-child) {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .chapteru-lists {
    padding: 15px 15px 15px 0;

    .el-divider--horizontal {
      margin: 5px;
    }

    .cl-subhint {
      display: flex;

      span {
        font-size: 14px;
        color: #666;

        &:nth-child(2) {
          margin-left: 15px;
        }
      }

      em {
        margin: 0 5px;
      }
    }

    .cl-btns {
      display: flex;
      align-items: center;
      width: 4rem;
      justify-content: space-between;

      i {
        color: #999999;
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }

  .el-icon-receiving {
    width: 42px;
    height: 42px;
    font-size: 40px;
    color: #999;
    margin-right: 15px;
  }

  .pageForm-btns {
    padding: 85px 0 15px;
    text-align: center;

    .el-button {
      width: 10rem;

      span {
        min-width: 4em;
      }
    }
  }

  .file-list-group {
    .list-group-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  .tree-drag-group {
    display: flex;
    align-items: center;
    font-size: 14px;

    .list-group-item {
      margin-left: 5px;
      display: inline-block;

      .el-icon-receiving {
        font-size: 22px;
        color: #2d2;
      }

      .df {
        display: none;
      }
    }
  }

  .bgc-height {
    background-color: #f4ff00;
  }
}

.btnbox {

  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
