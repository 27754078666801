
















































































































































































































.CourseTable {
  .el-radio {
    .el-radio__label {
      display: none;
    }
  }
}
