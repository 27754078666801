






















































































































































































































































































.formCom {
  .el-form-item {
    margin-bottom: 22px !important;
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
  }
  .upload-btn {
    height: 40px;
    .el-upload {
      height: 40px !important;
      border: none !important;
    }
  }
  .el-tree-node__content {
    height: 2rem !important;
  }
}
